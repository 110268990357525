import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import PQueue from "p-queue"
import Icon from "./Player.Icon"
import PlayerProgress from "./Player.Progress"
import useInterval from "../../utils/useInterval"
import { createPlayback as create, updatePlayback } from "../../api"

const queue = new PQueue({
  concurrency: 1,
  interval: 1000
})
const analyticsInterval = 10 * 1000
let playback = false
const Player = ({mediaUrl, episodeId, episode}) => {
  const audioPlayer = useRef(null)
  const [playerStatus, setPlayerStatus] = useState("stopped")
  const [duration, setDuration] = useState(
    parseFloat(localStorage.getItem(`${episodeId}-duration`) || 0)
  )
  const [currentTime, setCurrentTime] = useState(
    parseFloat(localStorage.getItem(`${episodeId}-progression`)) || 0
  )
  const [lastTime, setLastTime] = useState(currentTime)
  useInterval(async () => {
    if (playback && playerStatus === "playing") {
      await queue.add(async () => {
        let seconds = currentTime - lastTime
        await updatePlayback(episodeId, Math.round(seconds))
        setLastTime(currentTime)
      })
    }
  }, analyticsInterval)

  const createPlayback = async () => {

    if (!playback) {
      await queue.add(async () => {
        await create(
          episode,
          window.navigator.userAgent.replace(/\D+/g, "")
        )
        playback  = true
      })
    }
  }

  useEffect(() => {
    audioPlayer.current.addEventListener("loadedmetadata", e => {
      setDuration(e.target.duration)
      queue.add(() => {
        localStorage.setItem(`${episodeId}-duration`, e.target.duration)
      })
    })
    audioPlayer.current.addEventListener("timeupdate", e => {
      if (e.target.currentTime > 0) {
        setCurrentTime(e.target.currentTime)
        queue.add(() => {
          localStorage.setItem(`${episodeId}-progression`, e.target.currentTime)
        })
      }
    })

    const saved = localStorage.getItem(`${episodeId}-progression`)
    if (saved) {
      audioPlayer.current.currentTime = saved
    }

    audioPlayer.current.addEventListener("play", e => {
      if (!playback) {
        createPlayback().then(() => null)
      }
    })

    const reference = audioPlayer.current

    return () => {
      reference.removeEventListener("timeupdate")
      reference.removeEventListener("loadedmetadata")
      reference.removeEventListener("play")
    }
    // eslint-disable-next-line
  }, [])

  const handleButton = () => {
    switch (playerStatus) {
      case "stopped":
      case "paused":
        audioPlayer.current.play()
        setPlayerStatus("playing")
        break

      case "playing":
        audioPlayer.current.pause()
        setPlayerStatus("paused")
        break

      default:
        break
    }
  }

  const getTime = time => {
    if (!isNaN(time)) {
      return (
        Math.floor(time / 60) + ":" + ("0" + Math.floor(time % 60)).slice(-2)
      )
    }
  }

  const progression = currentTime / duration
  return (
    <Container>
      <div>
        <ButtonContainer>
          <PlayButton onClick={() => handleButton()} type="button">
            <Icon icon={playerStatus}/>
          </PlayButton>

          <Current>{getTime(currentTime)}</Current>
        </ButtonContainer>

        <audio ref={audioPlayer} preload="metadata">
          <source src={mediaUrl} type="audio/mp3"/>
        </audio>
      </div>

      <BarContainer>
        <PlayerProgress progression={progression}/>
      </BarContainer>
      <Duration>{getTime(duration)}</Duration>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const BarContainer = styled.div`
  flex-basis: 100%;
`

const ButtonContainer = styled.div`
  display: flex;
  margin-right: 12px;
  align-items: center;
`

const PlayButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: transparent;
  border: 3px solid ${p => p.theme.colors.primary};
  margin-right: 12px;
  cursor: pointer;
  padding: 0;

  &:focus,
  &:active,
  &:hover {
    outline: 0;
    border-color: ${p => p.theme.colors.tertiary};
  }
`

const Current = styled.span`
  color: #767676;
`

const Duration = styled(Current)`
  margin-left: 12px;
`

export default Player
