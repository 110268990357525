import React from "react"
import styled from "styled-components"
import { hideVisually } from "polished"

const PlayerProgress = ({ progression }) => {
  return (
    <Bg>
      <Bar progression={progression}>
        <Text>{progression >= 1 ? "100" : Math.round(progression * 100)}%</Text>
      </Bar>
    </Bg>
  )
}

const Bg = styled.div`
  position: relative;
  background-color: #ddd;
  height: 6px;
  border-radius: 3px;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
`

const Bar = styled.div`
  position: absolute;
  background-color: ${p => p.theme.colors.primary};
  left: 0;
  height: 100%;
  width: ${p =>
    isNaN(p.progression) ? "0" : p.progression > 1 ? "100" : p.progression * 100}%;
  border-radius: 0 3px 3px 0;
`

const Text = styled.span`
  ${hideVisually}
`

export default PlayerProgress
