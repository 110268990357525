import axios from "axios"

export const API_URL = process.env.REACT_APP_API_URL
const LATEST = "__latest"
const instance = axios.create({
  baseURL: API_URL,
  headers: {
    source: "Web",
    user: window.navigator.userAgent.replace(/\D+/g, "")
  }
})

console.log("Base url", instance.baseURL)
const mapData = item => item.data

export const latestEpisodeUrl = () => `${API_URL}/episode/${LATEST}`

export const getEpisode = async (slug) =>
  instance.get(`/episode/${slug || LATEST}`).then(mapData)

export const getLatest = async(series) => instance.get(`/series/${series}`).then(mapData)

export const createPlayback = async (episode) => instance.post(`/episode/${episode.id}`, {
  episodeId: episode.id,
  title: episode.title,
  seriesId: episode.series.id,
  seriesTitle: episode.series.title,
}).then(mapData)

export const updatePlayback = async(episodeId, seconds) => instance.put(`/episode/${episodeId}`, {seconds}).then(mapData)
