import React from "react"
import styled from "styled-components"

const PlayerIcon = ({ icon }) => {
  return (
    <Icon
      width="6"
      height="8"
      viewBox="0 0 6 8"
      xmlns="http://www.w3.org/2000/svg"
      status={icon}
    >
      {(icon === "paused" || icon === "stopped") && (
        <path d="M0 8L6 4L0 0V8Z" />
      )}
      {icon === "playing" && (
        <>
          <path d="M0 0H2V8H0V0Z" />
          <path d="M4 0H6V8H4V0Z" />
        </>
      )}
    </Icon>
  )
}

const Icon = styled.svg`
  width: 14px;
  height: 14px;
  fill: ${p => p.theme.colors.primary};
  ${p =>
    (p.status === "paused" || p.status === "stopped") &&
    `transform: translateX(2px);`}
   &:focus,
  &:active,
  &:hover {
    fill: ${p => p.theme.colors.tertiary};
  }
`

export default PlayerIcon
