import React, { useEffect, useState } from "react"
import { createGlobalStyle, ThemeProvider } from "styled-components"
import theme from "@lytt/rn-podcasts-theme"
import { Router } from "@reach/router"
import { getEpisode, getLatest } from "./api"
import Episode from "./components/Episode"

const Latest = props => {
  const [episode, setEpisode] = useState(null)

  const handleFetch = async () => {
    const content = await getLatest(props.series)
    setEpisode(content)
  }

  useEffect(() => {
    handleFetch()
    // eslint-disable-next-line
  }, [])

  if (!episode) {
    return null
  }

  return <Episode episode={episode}/>
}

const EpisodeSlug = props => {
  const [episode, setEpisode] = useState(null)

  const handleFetch = async () => {
    const content = await getEpisode(props.slug)
    setEpisode(content)
  }

  useEffect(() => {
    handleFetch()
    // eslint-disable-next-line
  }, [])

  if (!episode) {
    return null
  }

  return <Episode episode={episode}/>
}

const GlobalStyle = createGlobalStyle`
  @import {
    src: url("/fonts/HarrisonSerifPro-BLack.otf");
    font-family: HarrisonSerifPro;  
  }
  @import {
    src: url("/fonts/AtlasGroteskApp-Regular.ttf");
    font-family: Atlas Grotesk;  
  }
  body {
      font-family: HarrisonSerifPro,Georgia,Times,serif;
      font-size: 16px;
      line-height: 1.428571429;
      color: #1e2b46;
      background-color: #f9f9f5;
    }
`

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyle/>
        <Router>
          <Latest path={"player/serie/:series"}/>
          <EpisodeSlug path={"/player/:slug"}/>
        </Router>
      </>
    </ThemeProvider>
  )
}

export default App
