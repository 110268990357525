import React from "react"
import Player from "./Player/Player"
import styled from "styled-components"

const Episode = ({episode}) => {
  return (
    <div>
      <Outer>
        <div>
          <Img src={episode.imageUrl} alt={episode.title}/>
        </div>
        <Body>
        <Heading>
          {episode.series.title} — {episode.title}
        </Heading>
        <Player
          mediaUrl={episode.file.url}
          episodeId={episode.id}
          episode={episode}
        />
        </Body>
      </Outer>
      <Message>
        <strong>Neu!</strong> Hören Sie die RN-Podcasts hier im
        {" "}
        <a href="https://itunes.apple.com/" target="_parent">
          Appstore
        </a>{" "}
        oder bei{" "}
        <a href="https://play.google.com/store/apps/" target="_parent">
          Google Play.
        </a>
      </Message>
    </div>
  )
}

const Outer = styled.article`
  display: flex;
  margin-bottom: 12px;
`

const Img = styled.img`
  width: 80px;
  height: 80px;
  margin-right: 18px;
  border-radius: ${p => p.theme.borderRadius.image};

  @media only screen and (max-width: 320px) {
    width: 60px;
    height: 60px;
    margin-right: 12px;
  }
`

const Body = styled.div`
  flex-basis: 100%;
`

const Heading = styled.h1`
  font-size: 16px;
  font-weight: bold;
  font-family: Harrison Serif Pro;
  margin: 0 0 12px;

  @media only screen and (max-width: 320px) {
    margin-bottom: 6px;
  }
`

const Message = styled.p`
  //font-weight: bold;
  font-family: "Atlas Grotesk";
  margin: 0;

  > strong {
    color: ${p => p.theme.colors.primary}
  }

  > a {
    color: ${p => p.theme.colors.secondary}
    text-decoration: none;
  }
`

export default Episode
